import React, { useState, useRef, useCallback } from 'react';
import GlideJs, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import includes from 'array-includes';
import range from 'just-range';

import useIsomorphicLayoutEffect from 'core/hooks/useIsomorphicLayoutEffect';

import { Slide, SlidesLoading, GlideSlides } from './Glide.styles';
import MaybeBlankImage from '../MaybeBlankImage';
import LazyImage from '../LazyImage';

const useImagesLoadedHistory = imagesLength => {
  const [state, setState] = useState({
    0: true,
    1: true,
    [imagesLength - 1]: true,
  });

  const onImageLoad = useCallback(index => {
    setState(prevState => {
      const previouslyLoaded = prevState[index];

      if (!previouslyLoaded) {
        return { ...prevState, [index]: true };
      }

      return prevState;
    });
  }, []);

  return [state, onImageLoad];
};

const LazyLoader = (glide, components, Events) => {
  const LazyLoad = {
    runAfter() {
      Events.emit('image.loaded', glide.index);
    },
  };

  Events.on('run.after', () => {
    LazyLoad.runAfter();
  });

  return LazyLoad;
};

const useGlide = (options, onImageLoad) => {
  const ref = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderBuilding, setSliderBuilding] = useState(true);

  useIsomorphicLayoutEffect(() => {
    const domEl = ref.current;

    const glideInstance = new GlideJs(domEl, options);

    const handleAfterSlide = () => {
      const { index } = glideInstance;

      setCurrentIndex(index);
      onImageLoad(index);
    };

    glideInstance.on('image.loaded', handleAfterSlide);

    glideInstance.on('build.after', () => {
      setSliderBuilding(false);
    });
    requestAnimationFrame(() => glideInstance.mount({ Controls, Swipe, LazyLoader }));

    return () => glideInstance.destroy();
  }, [ref, options, onImageLoad]);

  return [ref, currentIndex, sliderBuilding];
};

const Glide = props => {
  const { images, options, children: controls, altText, lazyLoadFirstImage, imageLoading, height, width } = props;

  const imagesLength = images.length;

  const [imagesLoaded, onImageLoad] = useImagesLoadedHistory(imagesLength);
  const [elementRef, currentIndex, sliderBuilding] = useGlide(options, onImageLoad);

  const perView = options.perView || 1;

  // 1 previous image and perView + 1
  const lazyLoadIndices = range(currentIndex - 1, currentIndex + perView + 1);

  const slides = images.map(({ src, srcSet }, index) => {
    let imageElement = null;

    const imagePreviouslyLoaded = imagesLoaded[index];

    if (
      imagePreviouslyLoaded || // image already loaded into DOM
      includes(lazyLoadIndices, index) ||
      index + 1 === imagesLength // is last slide
    ) {
      imageElement = lazyLoadFirstImage ? (
        <LazyImage height={height} width={width} src={src} srcSet={srcSet} alt={altText || ''} />
      ) : (
        <MaybeBlankImage height={height} width={width} src={src} srcSet={srcSet} alt={altText || ''} />
      );
    }

    return <Slide key={src}>{imageElement}</Slide>;
  });

  const isInViewport = () => {
    if (!elementRef.current) return false;
    const { top } = elementRef.current.getBoundingClientRect();
    return top >= 0 && top <= window.innerHeight;
  };

  return (
    <div style={{ position: 'relative' }}>
      {((imageLoading && isInViewport()) || sliderBuilding) && <SlidesLoading />}
      <div className="glide" ref={elementRef}>
        <div data-glide-el="track" className="glide__track">
          <GlideSlides className="glide__slides">{slides}</GlideSlides>
        </div>

        <div data-glide-el="controls">{controls}</div>
      </div>
    </div>
  );
};

export default Glide;
