import React from 'react';
import { ThemeProvider } from 'styled-components';

import globalTheme from './globalTheme';

export default BaseComponent => props => (
  <ThemeProvider theme={globalTheme}>
    <BaseComponent {...props} />
  </ThemeProvider>
);
