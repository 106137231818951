import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Button as BaseButton } from 'core/ui';
import Icon from 'core/ui/Icon';
import Glide from 'components/Glide';

const arrowIconStyles = css`
  filter: drop-shadow(5px 5px 5px #222);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const BackArrowIcon = styled(Icon).attrs({
  size: 'lg',
  icon: 'chevron-left',
})`
  ${arrowIconStyles}
`;

const NextArrowIcon = styled(Icon).attrs({
  size: 'lg',
  icon: 'chevron-right',
})`
  ${arrowIconStyles}
`;

const Button = styled(BaseButton)`
  color: #fff;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;
  height: 100%;

  &:first-of-type {
    padding-right: 24px;
  }

  &:last-of-type {
    right: 0;
    padding-left: 24px;
  }

  &:hover {
    color: #ccc;
  }
`;

function ImageSlider({ images, alt, isSwipeable, lazyLoad, imageLoading, height, width }) {
  const opts = useMemo(
    () => ({
      type: 'carousel',
      gap: 0,
      perView: 1,
      dragThreshold: isSwipeable ? 80 : false,
      swipeThreshold: isSwipeable ? 80 : false,
    }),
    [isSwipeable],
  );

  return (
    <Glide
      images={images}
      options={opts}
      altText={alt}
      lazyLoadFirstImage={lazyLoad}
      imageLoading={imageLoading}
      height={height}
      width={width}
    >
      <Button
        type="button"
        variant="transparent"
        data-glide-dir="<"
        data-id="rvcard-carousel-prev"
        data-event-category="RV Details Page"
        data-event-action="Previous Image Button"
        data-event-label="Header"
        className="image-slider-pagination-button"
      >
        <BackArrowIcon />
        <span className="sr-only">Show previous image</span>
      </Button>
      <Button
        type="button"
        variant="transparent"
        data-glide-dir=">"
        data-id="rvcard-carousel-next"
        data-event-category="RV Details Page"
        data-event-action="Next Image Button"
        data-event-label="Header"
        className="image-slider-pagination-button"
      >
        <NextArrowIcon />
        <span className="sr-only">Show next image</span>
      </Button>
    </Glide>
  );
}

function areEqual(prevProps, nextProps) {
  return (
    prevProps.imageLoading === nextProps.imageLoading && prevProps.lazyLoad === nextProps.lazyLoad
  );
}

export default React.memo(ImageSlider, areEqual);
