import React, { useMemo, useEffect, useState } from 'react';

import LazyImage from 'components/LazyImage';
import MaybeBlankImage from 'components/MaybeBlankImage';
import ToggleFavoriteButton from 'components/ToggleFavoriteButton';

import ImageSlider from './ImageSlider';

import {
  SingleThumbnailWrapper,
  ThumbnailWrapper,
  ThumbnailHeadlineOverlay,
  ThumbnailFavoriteOverlay,
} from './RVCard.styles';

const DEFAULT_IMAGE_WIDTH = '590';
const DEFAULT_IMAGE_HEIGHT = '456';
const imgSrcBase =
  'https://d3adfz34ynqwkr.cloudfront.net/image/upload/w_295,h_228,c_fill,g_center,f_auto,q_auto/rvs-images';

const createImageSource = img => ({
  src: `${imgSrcBase}/${img}`,
  srcSet: `${imgSrcBase}/${img}, https://d3adfz34ynqwkr.cloudfront.net/image/upload/w_295,h_228,c_fill,g_center,f_auto,q_auto,dpr_2.0/rvs-images/${img} 2x`,
});

const SingleThumbnail = ({ lazyLoad, children, ...props }) => {
  return (
    <SingleThumbnailWrapper>
      {lazyLoad ? (
        <LazyImage {...props} height={DEFAULT_IMAGE_HEIGHT} width={DEFAULT_IMAGE_WIDTH} />
      ) : (
        <MaybeBlankImage {...props} height={DEFAULT_IMAGE_HEIGHT} width={DEFAULT_IMAGE_WIDTH} />
      )}
      {children}
    </SingleThumbnailWrapper>
  );
};

const CarouselThumbnail = ({
  images,
  lazyLoad,
  alt,
  children,
  isMinimalCard,
  imageLoading,
  isMobile,
}) => {
  const isSwipeable = isMobile && !isMinimalCard;
  return (
    <ThumbnailWrapper isSwipeable={isSwipeable}>
      <ImageSlider
        images={images}
        lazyLoad={lazyLoad}
        alt={alt}
        isSwipeable={isSwipeable}
        imageLoading={imageLoading}
        height={DEFAULT_IMAGE_HEIGHT}
        width={DEFAULT_IMAGE_WIDTH}
      />
      {children}
    </ThumbnailWrapper>
  );
};

// Tell the component not to use the image carousel until after the first render
const useLazyCarousel = imageCount => {
  const [attachCarousel, setAttachCarousel] = useState(false);

  useEffect(() => {
    if (imageCount > 1) {
      setAttachCarousel(true);
    }
  }, [imageCount]);

  return attachCarousel;
};

const Thumbnail = props => {
  const {
    rvId,
    images,
    isFavorited,
    makeModel,
    year,
    ownerId,
    make,
    model,
    headline,
    nightlyRate,
    offersDelivery,
    classType,
    lazyLoad,
    isMinimalCard,
    imageLoading,
    isMobile,
    lat,
    lng,
    sleeps,
    isSingleImageCard,
  } = props;

  const thumbnailImages = useMemo(() => images.map(createImageSource), [images]);
  const isCarousel = useLazyCarousel(thumbnailImages.length);

  const overlays = (
    <>
      <ThumbnailHeadlineOverlay>
        <h4>{makeModel}</h4>
        <h5>
          {year} / {classType}
        </h5>
      </ThumbnailHeadlineOverlay>
      {!isSingleImageCard && (
        <ThumbnailFavoriteOverlay>
          <ToggleFavoriteButton
            {...{
              rvId,
              ownerId,
              year,
              make,
              model,
              headline,
              nightlyRate,
              offersDelivery,
              isFavorited,
              classType,
              location: {
                lat,
                log: lng,
              },
              sleeps,
              images: thumbnailImages,
            }}
          />
        </ThumbnailFavoriteOverlay>
      )}
    </>
  );

  return isCarousel ? (
    <CarouselThumbnail
      images={thumbnailImages}
      lazyLoad={lazyLoad}
      alt={makeModel}
      isMinimalCard={isMinimalCard}
      imageLoading={imageLoading}
      isMobile={isMobile}
    >
      {overlays}
    </CarouselThumbnail>
  ) : (
    <SingleThumbnail
      {...thumbnailImages[0]}
      lazyLoad={lazyLoad}
      alt={makeModel}
      width={DEFAULT_IMAGE_WIDTH}
      height={DEFAULT_IMAGE_HEIGHT}
    >
      {overlays}
    </SingleThumbnail>
  );
};

export default Thumbnail;
