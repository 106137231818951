import styled, { keyframes } from 'styled-components';

export const GlideSlides = styled.ul`
  width: 100%;
  height: 0;
  padding-bottom: 77.31959%;
  position: relative;
`;

export const Slide = styled.li`
  -moz-user-select: none;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  img {
    width: 100%;
    height: auto;
    -moz-user-select: none;
    pointer-events: none;
  }
`;

const shimmerKeyframes = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const SlidesLoading = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 77.31959%;
  position: absolute;
  z-index: 9999;
  height: 100%;
  background: linear-gradient(270deg, #e6e6e6, #d2d2d2);
  background-size: 500%;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmerKeyframes};
  animation-timing-function: ease-in-out;
`;
