import React from 'react';
import { canInstantBook } from 'core/helpers/RV';
import withGlobalTheme from 'core/styles/withGlobalTheme';
import RVCard from './RVCard';

const sanitizeImages = images => (images || []).filter(Boolean);

export default withGlobalTheme(React.memo(props => {
  const {
    rv: { id: rvId, attributes: rv },
    lazyLoad,
    searchLocation,
    isMobile,
    imageLoading = false,
    isMinimalCard = false,
    isSimpleCard = false,
    isSingleImageCard = false,
    isFleet,
    position,
    trackClick,
    index,
  } = props;
  const images = isSingleImageCard ? [rv.thumbnail?.filename] : sanitizeImages(rv.thumbnails);

  return (
    <RVCard
      images={images}
      lazyLoadImage={lazyLoad}
      isFavorited={rv.favorited}
      isInstantBook={canInstantBook(rv.is_instant_book, rv.insurance_status)}
      offersDelivery={rv.delivery}
      rvId={rvId}
      year={rv.rv_year}
      make={rv.make}
      model={rv.model}
      headline={rv.headline}
      ownerId={(rv.owner || {}).id}
      makeModel={rv.rv_make_model}
      classType={rv.type}
      sleeps={rv.how_many_it_sleeps}
      nightlyRate={rv.rate}
      reviewScore={rv.reviews.score}
      searchLocation={searchLocation}
      distanceFromSearchLocation={rv.location.distance}
      isMinimalCard={isMinimalCard}
      imageLoading={imageLoading}
      isMobile={isMobile}
      isSimpleCard={isSimpleCard}
      location={rv.location.name}
      state={rv.location.state}
      lat={rv.location.lat}
      lng={rv.location.lng}
      image={rv.thumbnail.filename}
      isFleet={isFleet}
      position={position}
      isSingleImageCard={isSingleImageCard}
      trackClick={trackClick}
      index={index}
      isNewListing={rv.new_listing_without_reviews}
    />
  );
}));
