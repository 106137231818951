import React from 'react';

import { Flex, Box, Text } from 'core/ui';
import { Hide } from 'core/styles/utils';

import * as Formatters from 'core/helpers/Formatters';

import { useImpressionTracker } from 'impression-tracker-react-hook';
import { eventLogger, handleError } from '../../../tracking/PromotedAi';
import {
  Card,
  MetaWrapper,
  MetaText,
  RateText,
  RateTextMinimal,
  RateDescription,
  HoverLink,
  RateWrapper,
  RatingsWrapper,
  MetaInnerWrapper,
  RatingInstantBookWrapper,
  MobileBullet,
  StyledInstantBookIcon,
  InstantBookLabel,
  MetaLocationBox,
  InstantBookBox,
  DeliveryBox,
  MetaTextDeliveryStyled,
  StyledSimpleCard,
} from './RVCard.styles';

import Thumbnail from './Thumbnail';
import Ratings from './Ratings';

import BedIcon from './icons/BedIcon';
import DeliveryIcon from './icons/DeliveryIcon';
import MapMarkerIcon from './icons/MapMarkerIcon';

const MetaTextDelivery = ({ children }) => {
  return <MetaTextDeliveryStyled>{children}</MetaTextDeliveryStyled>;
};

const MetaTextWithIcon = ({ icon: Icon, textAlign, children, hideIconOnMobile, as: element }) => {
  return (
    <MetaText textAlign={textAlign} as={element || 'div'}>
      <Hide as="span" mobile={hideIconOnMobile} small={hideIconOnMobile}>
        <Text as="span" mr={1}>
          <Icon />
        </Text>
      </Hide>
      {children}
    </MetaText>
  );
};

function RVCard(props) {
  const {
    isFavorited,
    isInstantBook,
    offersDelivery,
    rvId,
    year,
    make,
    model,
    headline,
    ownerId,
    makeModel,
    classType,
    sleeps,
    nightlyRate,
    reviewScore,
    searchLocation,
    distanceFromSearchLocation,
    isMinimalCard,
    imageLoading,
    isMobile,
    isSimpleCard,
    location,
    state,
    lat,
    lng,
    image,
    images = [],
    lazyLoadImage: lazyLoad,
    isFleet,
    position,
    isSingleImageCard,
    isNewListing,
  } = props;

  const href = `/rvs/details/${rvId}`;
  const linkTarget = `rv_${rvId}`;
  const newListingBadgeStyles = {
    img: {
      marginBottom: '5px',
    },
    fontSize: '13px',
  };

  const [containerRef, impressionId, logImpressionFunctor] = useImpressionTracker({
    enable: true,
    contentId: rvId,
    handleError,
    logImpression: eventLogger.logImpression
  });

  function trackPromotedAiClick() {
    logImpressionFunctor();
    eventLogger.logClick({
      impressionId,
      contentId: rvId,
      targetUrl: href,
    });
  }

  const renderMetaInner = () => (
    <>
      <RatingInstantBookWrapper>
        <RatingsWrapper isInstantBook={isInstantBook}>
          <Ratings score={reviewScore} isNewListing={isNewListing} styles={newListingBadgeStyles} />
        </RatingsWrapper>

        {!!isInstantBook && (
          <InstantBookBox>
            <MetaTextWithIcon icon={() => <StyledInstantBookIcon />} textAlign="right">
              <InstantBookLabel>Instant Book</InstantBookLabel>
            </MetaTextWithIcon>
          </InstantBookBox>
        )}
      </RatingInstantBookWrapper>

      <MetaInnerWrapper mb={1}>
        <Flex flexWrap="wrap" justifyContent="space-between" alignItems="baseline">
          <Box>
            <MetaTextWithIcon icon={BedIcon} as="span" hideIconOnMobile>
              Sleeps {sleeps}
            </MetaTextWithIcon>
            {!isSingleImageCard && <MobileBullet />}
          </Box>
          <DeliveryBox>
            {!!offersDelivery && (
              <MetaTextDelivery>
                <MetaTextWithIcon icon={DeliveryIcon} hideIconOnMobile>
                  Offers Delivery
                </MetaTextWithIcon>
              </MetaTextDelivery>
            )}
          </DeliveryBox>
          {searchLocation && (
            <MetaLocationBox mt={{ md: 3 }}>
              <MetaTextWithIcon icon={MapMarkerIcon} as="span" hideIconOnMobile>
                <span>
                  {Formatters.float(distanceFromSearchLocation, 1)} miles from {searchLocation}
                </span>
              </MetaTextWithIcon>
            </MetaLocationBox>
          )}
          {isFleet && (
            <MetaLocationBox mt={{ md: 3 }}>
              <MetaTextWithIcon icon={MapMarkerIcon} as="span" hideIconOnMobile>
                <span>
                  RV Located in {location}
                  {state ? `, ${state}` : ''}, USA
                </span>
              </MetaTextWithIcon>
            </MetaLocationBox>
          )}
        </Flex>
      </MetaInnerWrapper>

      <RateWrapper>
        <RateText>{Formatters.currency(nightlyRate)}</RateText>
        <RateDescription>PER NIGHT</RateDescription>
      </RateWrapper>
    </>
  );

  const renderMinimalMeta = () => (
    <Flex justifyContent="space-between" px={3}>
      <Box>
        <RateTextMinimal>{Formatters.currency(nightlyRate)}</RateTextMinimal>
        <RateDescription>PER NIGHT</RateDescription>
      </Box>
      {!!isInstantBook && (
        <Box>
          <MetaTextWithIcon icon={() => <StyledInstantBookIcon />} textAlign="right">
            <InstantBookLabel>Instant Book</InstantBookLabel>
          </MetaTextWithIcon>
        </Box>
      )}
    </Flex>
  );

  if (isSimpleCard) {
    return (
      <StyledSimpleCard ref={containerRef}>
        <a
          href={href}
          onClick={trackPromotedAiClick}
          target={linkTarget}
          data-event-category="RV StyledSimpleCard"
          data-event-action="RV Card"
          data-event-label="RV Card"
          data-event-index={position}
        >
          <img
            src={`https://d3adfz34ynqwkr.cloudfront.net/image/upload/w_295,h_228,c_fill,g_center,f_auto,q_auto/rvs-images/${image}`}
            alt={`RV in ${location}`}
            loading="lazy"
            style={{ width: '100%' }}
          />
          <h3>${nightlyRate}</h3>
          <h4>
            {year} {makeModel}
          </h4>
          <p>{location}</p>
          <p>
            {classType} / Sleeps {sleeps}
          </p>
        </a>
      </StyledSimpleCard>
    );
  }

  function trackClick() {
    if (props.trackClick) {
      props.trackClick(props.index, href)
    }

    trackPromotedAiClick();
  }

  return (
    <Card data-id="rv-card" ref={containerRef}>
      <a
        href={href}
        target={linkTarget}
        onClick={trackClick}
        data-event-category="RV Card"
        data-event-action="RV Card"
        data-event-label="RV Card"
        data-event-index={position}
      >
        <Thumbnail
          {...{
            lazyLoad,
            rvId,
            images,
            isFavorited,
            year,
            make,
            model,
            headline,
            ownerId,
            nightlyRate,
            offersDelivery,
            makeModel,
            classType,
            isMinimalCard,
            imageLoading,
            isMobile,
            lat,
            lng,
            sleeps,
            isSingleImageCard
          }}
        />
      </a>

      <MetaWrapper>
        {isMinimalCard ? renderMinimalMeta() : renderMetaInner()}
        <HoverLink
          href={href}
          target={linkTarget}
          data-event-category="RV Card"
          data-event-action="RV Card"
          data-event-label="RV Card"
        >
          View This RV
        </HoverLink>
      </MetaWrapper>
    </Card>
  );
}

export default RVCard;
